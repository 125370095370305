@import '../../assets/styles/variables';
.bet-settings {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.wrap {
  width: 100%;
  max-width: 375px;
  margin: auto;

  @media screen and (min-width: $mobile-landscape-min) and (orientation: landscape) {
    margin-top: 70px;
    max-width: 640px;
  }

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    margin-top: 50px;
    max-width: 460px;
  }
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    margin-top: 50px;
    max-width: 894px;
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    scale: 0.7;
    max-width: 100%;
    margin-top: 30px;
  }

  @media screen and (max-height: 650px) and (orientation: portrait) {
    scale: 0.7;
    max-width: 100%;
  }
}

.currency {
  color: #fff;
  font-size: 16px;
  margin-bottom: 8px;
  text-align: center;
  font-weight: 900;
  margin-bottom: 18px;

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 26px;
    margin-bottom: 11px;
  }
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 26px;
    margin-bottom: 11px;
  }

}

.amount {
  color: #fff;
  font-size: 26px;
  text-align: center;
  margin-bottom: 5px;

  &_value {
    color: var(--game-color);
  }

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 16px;
  }
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 16px;
  }
}

.bet-settings::-webkit-scrollbar {
  display: none;
}

.range-slider {
  width: 240px;
  margin: 0 auto;

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    width: 310px;
  }
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    width: 310px;
  }
}

.fast-bet {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    margin-bottom: 22px;
  }
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    margin-bottom: 22px;
  }

  &__item {
    width: 20%;
    padding: 0 7.5px 16px;

    .btn {
      width: 100%;
    }

    @media screen and (orientation: portrait) {
      width: 50%;
    }

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      padding: 0 10px 22px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      padding: 0 10px 22px;
    }
   
  }
}
