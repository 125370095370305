@import '../../assets/styles/variables';
.logo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .companyLogo {
    width: 300px;
    height: auto;
    opacity: 0.5;

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      width: 480px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      width: 480px;
    }
  }
  .companyLogo_loaded {
    position: absolute;
    width: 300px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    transition: height ease 0.5s;

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      width: 480px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      width: 480px;
    }
    
    img {
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.loadScreenWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 11% 11%;
  background-color: #000;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
