@import '../../assets/styles/variables';
.info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 16px;

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
  .wrap {
    max-width: 554px;
    margin: auto;

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      max-width: 588px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      max-width: 956px;
    }
  }
  .container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 7.5vh;

    &::-webkit-scrollbar-track {
      background-color: #010101;
    }

    &::-webkit-scrollbar {
      width: 8px;

      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        width: 13px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        width: 13px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--game-color);
    }
  }
}

.title {
  color: var(--game-color);
  font-size: 32px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 44px;
    margin: 64px 0;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 44px;
    margin: 64px 0;
  }
}

.subtitle {
  color: var(--game-color);
  font-size: 24px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 32px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    margin: 64px 0;
  }
}

.row {
  display: flex;
  margin: 16px 0;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  .col {
    width: 100%;
    padding: 0 16px;
    text-align: left;

    &.center {
      justify-content: center;
      text-align: center;
    }

    p {
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      text-align: inherit;
      margin: 0 0 10px;

      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 26px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 26px;
      }

      &.center {
        text-align: center;
      }

      span {
        color: var(--game-color);
      }
    }
  }
}

.p {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-align: left;
  margin: 0 0 10px;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 26px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 26px;
  }

  &.center {
    text-align: center;
  }

  span {
    color: var(--game-color);
  }
}

.figcontainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.fig {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;

  p.caption {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    margin: 0;
  }
  &__img {
    position: relative;
    display: block;
  }
  &__text {
    position: absolute;
    left: -3px;
    top: 50%;
    width: 100%;
    text-align: center;
    font-weight: bold;
    transform: translateY(-50%);
    &_gradient {
      background: -webkit-linear-gradient(
        #ffb3b3,
        #ff0000,
        #a30000,
        #f53e5b,
        #ff3728,
        #c9166e,
        #ac008a,
        #5b0038,
        #ffffff
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      filter: drop-shadow(0px -1px 0px #fff) drop-shadow(-2px 0px 0px #fff) drop-shadow(3px 0px 1px #fff)
        drop-shadow(-1px 2px 0px #fff) drop-shadow(0px -1px 0px #fff) drop-shadow(2px 3px 0px #000);
    }
  }
}

.image {
  display: flex;
  flex-direction: column;

  img,
  svg {
    display: block;
    width: 56px;
    height: 56px;
    margin: 0 auto 8px;
  }

  &__text {
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
}

.color {
  color: var(--game-color);
}

.paytable-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0 8px 16px;

    @media screen and (orientation: landscape) {
      width: 33.333%;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      padding: 0 16px 32px;
    }
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      padding: 0 16px 32px;
    }

    .additional-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
    }

    .img {
      display: block;
      width: 50%;
      position: relative;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .content {
      white-space: nowrap;
      width: 50%;
      padding-left: 8px;
      align-items: flex-start;
      justify-content: center;
      font-size: 0.65rem;
      color: var(--game-color);
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 1rem;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 1rem;
      }

      @media screen and (max-width: 400px) and (orientation: portrait) {
        font-size: 10px;
      }

      .multiplier {
        color: #fff;
      }
    }
  }

  &.wild {
    display: flex;
    word-break: break-word;
    white-space: break-spaces;
    .paytable-list__item {
      flex-direction: column;
      // width: 50%;
      padding-bottom: 32px;
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        padding-bottom: 64px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        padding-bottom: 64px;
      }

      .img {
        width: 100%;
        margin-bottom: 8px;

        .image__title {
          color: #fff;
          text-align: center;
          font-size: 24px;
          margin-bottom: 8px;
          @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
            font-size: 32px;
          }
          @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
            font-size: 32px;
          }
        }
      }
      .content {
        width: auto;
        padding: 0;
        font-size: 14px;
        @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
          font-size: 26px;
        }
        @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
          font-size: 26px;
        }
      }
    }
  }
}

.payLines {
  display: block;
  width: 100%;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    margin: 0 auto;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
}

.buttonBehaviors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  &__item {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #fff;
    padding: 0 8px 16px;
    text-align: center;

    @media screen and (orientation: portrait) {
      width: 50%;
    }

    svg {
      display: block;
      width: 56px;
      height: 56px;
      margin-bottom: 8px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    }
    &.start {
      width: 100%;
      svg {
        width: 210px;
      }
    }
    &.spin {
      margin-left: 25%;
      svg {
        width: 128px;
        height: 128px;
      }
    }
    &.stop {
      margin-right: 25%;
      svg {
        width: 128px;
        height: 128px;
      }
    }

    @media screen and (orientation: portrait) {
      &.start,
      &.spin,
      &.stop {
        margin: 0 !important;
      }
    }
  }
}
.position {
  display: inline-block;
  background-position: 50%;
  background-size: contain;
}
.buy_feature {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 131px;
  margin: auto;
  &__img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 166px;
    height: 141px;
  }
  &__text {
    position: relative;
    text-align: center;
    font-size: 24px;
    word-break: break-word;
    align-items: center;
    line-height: 1;
    color: #ffaa01;
    text-shadow: -1px -1px 0 #631800, 1px -1px 0 #631800, -1px 1px 0 #631800, 1px 1px 0 #631800;
    padding: 8px;
  }
}
