@import '../../assets/styles/variables';
.wrap {
  margin: 20vh auto;

  @media screen and (orientation: portrait) {
    margin: 12vh auto;
  }

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    margin-top: 120px;
    max-width: 600px;
  }
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    margin-top: 120px;
    max-width: 940px;
  }

  @media screen and (max-width: $mobile-portrait-min) and (orientation: portrait) {
    margin: 10vh auto;
    scale: 0.8;
  }
  @media screen and (max-width: $mobile-landscape-min) and (orientation: landscape) {
    margin: 12vh auto;
    scale: 0.7;
  }
}

.container {
  width: 100%;
  overflow: hidden;
  padding: 0 16px;
}

.autoplay-settings {
  display: flex;

  &__item {
    display: flex;
    margin-bottom: 16px;

    &.btns {
      display: block;

      .label {
        margin-bottom: 11px;
      }
    }
  }

  &__col {
    width: 100%;
    @media screen and (orientation: landscape) {
      width: 50%;
      &:nth-child(1) {
        margin-right: 16px;
      }
      &:nth-child(2) {
        margin-left: 16px;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
  }

  .toggle {
    margin-right: 8px;
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      margin-right: 22px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      margin-right: 22px;
    }
  }

  .slider {
    width: 230px;
    margin-top: 4px;
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      width: 290px;
      margin-top: 8px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      width: 290px;
      margin-top: 8px;
    }
  }

  @media only screen and (orientation: portrait) {
    flex-wrap: wrap;
    &__col {
      width: 100%;
      margin: 0;
    }
  }
}

.autoplay-settings::-webkit-scrollbar {
  display: none;
}

.label {
  font-size: 12px;
  font-weight: 700;
  color: #fff;

  .value {
    display: block;
    color: var(--game-color);
  }

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 20px;
  }
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 20px;
  }
}

.input {
  font-family: var(--font-family);
  padding: 1px 2px;
  letter-spacing: 0px;
}
