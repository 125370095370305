@import './variables';
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  bottom: var(--bottom-height-percent-landscape);
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);


  // tablet and small desktop
  @media only screen and (orientation: portrait) {
    height: calc(100% - var(--bottom-height-percent-portrait));
  }

  &__title {
    position: absolute;
    top: 30px;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin: 0;
    color: var(--game-color);
    font-weight: 900;

    @media screen and (min-width: $mobile-portrait-min) and (orientation: portrait) {
      top: 35px;
    }

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      font-size: 44px;
      line-height: 48px;
      top: 42px;
    }

    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      font-size: 44px;
      line-height: 48px;
      top: 42px;
    }
  }
}
